
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachMatchesNavigationVue from "@/components/coach/matches/navigation.vue";
import AppCoachMatchesCategoriesListIndexVue from "@/views/app/coach/matches/categories/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachMatchesNavigationVue,
    AppCoachMatchesCategoriesListIndexVue,
  },
})
export default class AppCoachMatchesCategoriesIndexVue extends Vue {}
